import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    public: true,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    public: false,
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
    public: true,
  },
  {
    path: "/career",
    name: "career",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/CarreerView.vue"),
    public: true,
  },
  // {
  //   path: "/workshop",
  //   name: "workshop",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "workshop" */ "../views/workshop/WorkshopDetails.vue"
  //     ),
  //   public: false,
  // },
  {
    path: "/MNB-munkaltatoi-marka-felmeres-jatekszabalyzat",
    name: "MNBGameEmployerCorp",
    component: () =>
      import(
        /* webpackChunkName: "workshopSuccess" */ "../views/MNBGameRulesEmployerView.vue"
      ),
    public: false,
  },
  {
    path: "/MNB-vallalati-marka-felmeres-jatekszabalyzat",
    name: "MNBGameRulesCorp",
    component: () =>
      import(
        /* webpackChunkName: "workshopSuccess" */ "../views/MNBGameRulesCorpView.vue"
      ),
    public: false,
  },
  {
    path: "/koszonjuk-a-jelentkezest",
    name: "workshopSuccess",
    component: () =>
      import(
        /* webpackChunkName: "workshopSuccess" */ "../views/workshop/WorkshopSuccess.vue"
      ),
    public: false,
  },
  {
    path: "/hiba-tortent",
    name: "workshopError",
    component: () =>
      import(
        /* webpackChunkName: "workshopError" */ "../views/workshop/WorkshopError.vue"
      ),
    public: false,
  },
  {
    path: "/success",
    name: "success",
    component: () =>
      import(/* webpackChunkName: "success" */ "../views/SuccessView.vue"),
    public: false,
  },
  {
    path: "/hu_HU/general-terms-and-conditions",
    name: "generalTermsAndConditions",
    component: () =>
      import(
        /* webpackChunkName: "generalTermsAndConditions" */ "../views/AszfView.vue"
      ),
    public: false,
  },
  {
    path: "/hu_HU/privacy",
    name: "privacy",
    component: () =>
      import(
        /* webpackChunkName: "privacyPolicy" */ "../views/PrivacyView.vue"
      ),
    public: false,
  },
  {
    path: "/general-terms-en",
    name: "generalTermsEn",
    component: () =>
      import(
        /* webpackChunkName: "generalTermsEn" */ "../views/TermsAndConditionsView.vue"
      ),
    public: false,
  },
  {
    path: "/test",
    name: "test",
    component: () =>
      import(
        /* webpackChunkName: "privacyPolicy" */ "../views/TestView.vue"
      ),
    public: false,
  },

  // 404 catch all
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/404.vue"),
    public: false,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
